import { defineAsyncComponent } from 'vue';
import { defineAEMCustomElement } from '@dh-io-emh/shared/src/utils/defineAEMCustomElement.util';
import { Logger } from '@dh-io-emh/shared/src/utils/logger.util';

const TAG_NAME = 'emh-context-banner';

defineAEMCustomElement({
  componentTag: TAG_NAME,
  MainComponent: defineAsyncComponent(() => import('./App.vue')),
  LoadingComponent: defineAsyncComponent(
    () => import('./components/contextBannerLoadingSkeleton/ContextBannerLoadingSkeleton.vue'),
  ),
}).then(() => {
  Logger.info(`${TAG_NAME} loaded successfully`);
});
